.table-header,
.table-header-collapsible {
  min-height: auto;
  padding: 26px;
  border-bottom: 1px solid rgb(234, 236, 239);
  display: flex;
  flex-direction: row;
}

.table-header-collapsible {
  padding: 26px 26px 26px 16px;
  display: flex;
  justify-content: flex-start;
}

.-opened {
  background-color: rgba(247, 249, 252, 1);
  transition: all 0.2s ease;
}

.table-header-collapsible h4 {
  padding-left: 20px;
  width: 100%;
}

.table-header-buttons {
  margin-left: auto;
}

.table-header-buttons button {
  vertical-align: middle;
  margin-left: 12px;
}
