@import url('https://fonts.googleapis.com/css?family=Roboto');

.typography-label {
  font-family: 'Roboto';
  font-style: normal;
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.6px;
}
