::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(168, 173, 180, 1);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(168, 173, 180, 1);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgba(168, 173, 180, 1);
}
