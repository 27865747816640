@import url('https://fonts.googleapis.com/css?family=Roboto');

.VaalTextField .MuiOutlinedInput-notchedOutline {
  border-color: #a8adb4;
  padding: 0 6px;
}

.VaalTextField .MuiOutlinedInput-notchedOutline span {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.6px;
}


.VaalTextField input.MuiOutlinedInput-input {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.VaalTextField .MuiInputBase-root {
  min-width: 296px;
}

.VaalTextField .MuiInputBase-root,
.VaalTextField .MuiFormLabel-root {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #091e42;
  background-color: #fff;
}

.VaalTextField .MuiFormLabel-root.Mui-focused,
.VaalTextField .MuiFormLabel-root.Mui-disabled.MuiInputLabel-shrink,
.VaalTextField .MuiFormLabel-root.Mui-error.MuiInputLabel-shrink {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.6px;
}

.VaalTextField .MuiFormLabel-root.Mui-focused {
  color: #2f61e0;
}
.VaalTextField .MuiFormLabel-root.Mui-disabled,
.VaalTextField .MuiInputBase-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.VaalTextField .MuiFormLabel-root.Mui-error {
  color: #e01e5a;
}

.VaalTextField .MuiFormLabel-colorSecondary.Mui-focused {
  color: #f50057;
}
.VaalTextField .MuiFormLabel-asterisk.Mui-error {
  color: #e01e5a;
}

.VaalTextField .MuiInputLabel-outlined {
  transform: translate(16px, 13px) scale(1);
}

.VaalTextField .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(12px, -8px) scale(1);
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.6px;
  background-color: transparent;
}

.VaalTextField .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: rgba(9, 30, 66, 1);
}
@media (hover: none) {
  .VaalTextField .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgba(9, 30, 66, 1);
  }
}

.VaalTextField
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #2f61e0;
  border-width: 1px;
}

.VaalTextField
  .MuiOutlinedInput-root.Mui-error
  .MuiOutlinedInput-notchedOutline {
  border-color: #e01e5a;
}
.VaalTextField
  .MuiOutlinedInput-root.Mui-disabled
  .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.26);
}

.VaalTextField
  .MuiOutlinedInput-colorSecondary.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #e01e5a;
}

.VaalTextField .MuiTypography-colorTextSecondary {
  color: #091e42;
}

.VaalTextField .MuiTypography-root {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: rgba(9, 30, 66, 1);
}

.VaalTextField .MuiOutlinedInput-adornedEnd {
  padding-right: 21px;
}

.VaalTextField .MuiOutlinedInput-adornedEnd.MuiButtonBase-root {
  padding-right: 0px;
}

.VaalTextField .MuiInputAdornment-root svg {
  max-height: none;
  max-width: none;
  height: auto;
  width: max-content;
}

.VaalTextField .MuiFormHelperText-root {
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
  letter-spacing: 0.6px;
  color: #a8adb4;
  margin-bottom: -19px;
}

.VaalTextField .MuiFormHelperText-root.Mui-error {
  color: #e01e5a;
}
.VaalTextField .MuiFormHelperText-marginDense {
  margin-top: 4px;
}
.VaalTextField .MuiFormHelperText-contained {
  margin-left: 12px;
  margin-right: 12px;
}

.VaalTextField .MuiOutlinedInput-adornedEnd > .MuiInputAdornment-positionEnd > button {
  padding-right: 0px;
  margin-right: -12px;
}

.VaalTextField .MuiOutlinedInput-multiline {
  padding: 12.5px 14px;
}