@import url('https://fonts.googleapis.com/css?family=Roboto');

.VTable .MuiTableContainer-root {
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05), 0px 25px 30px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
}

.VTable .MuiTable-root {
  min-width: 350px;
}

.VTable .MuiTableHead-root {
  background-color: rgba(247, 249, 252, 1);
}

.VTable .MuiTableRow-root:not(.MuiTableRow-head):not(.MuiTableRow-footer):hover {
  cursor: pointer;
  background-color: rgba(47, 97, 224, 0.1);
}

.VTable .MuiTableRow-root:hover a {
  color: rgba(47, 97, 224, 1);
}

.VTable .MuiTableCell-root {
  border-bottom: 1px solid rgb(234, 236, 239);
}

.VTable .MuiTableCell-root.MuiTableCell-footer {
  border-bottom: none;
}

.VTable .MuiTableCell-head {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.6px;
  color: rgba(9, 30, 66, 1);
  padding: 12px 16px;
}

.VTable .MuiTableCell-body {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: rgba(9, 30, 66, 1);
  padding: 20px 16px;
}

.VTable .MuiTableCell-sizeSmall {
  padding: 8px 16px;
}

.VTable .MuiToolbar-regular {
  min-height: auto;
  padding: 26px;
  border-bottom: 1px solid rgb(234, 236, 239);
}

/*-----------------------------------------*/
/* Works on Chrome, Edge, and Safari */
.VTable .MuiTableContainer-root::-webkit-scrollbar {
  width: 6px;
}

.VTable .MuiTableContainer-root::-webkit-scrollbar-track {
  background: transparent;
}

.VTable .MuiTableContainer-root::-webkit-scrollbar-thumb {
  background-color: #a8adb4;
  border-radius: 8px;
  border: 3px solid transparent;
}

/* Works on Firefox */
.VTable .MuiTableContainer-root {
  scrollbar-width: thin;
  scrollbar-color: #a8adb4 transparent;
}
