@import url('https://fonts.googleapis.com/css?family=Roboto');
@import '../VTable/VTable.css';

.VTable .collapsible-table-row .MuiTableCell-root:first-child,
.VTable .collapsible-table-row-opened .MuiTableCell-root:first-child {
  width: 24px;
  padding-left: 16px;
}

.VTable .collapsible-table-row th,
.VTable .collapsible-table-row-opened th {
  padding: 26px 4px;
}

.VTable .collapsible-table-row th.MuiTableCell-sizeSmall,
.VTable .collapsible-table-row-opened th.MuiTableCell-sizeSmall {
  padding: 16px 4px;
}

.VTable .collapsible-table-row-opened {
  background-color: rgba(247, 249, 252, 1);
}

.VTable .MuiIconButton-sizeSmall {
  padding: 0;
}

.VTable .MuiTableRow-root.collapsed-table {
  background-color: rgba(247, 249, 252, 1);
}

.VTable .MuiTableRow-root.collapsed-table:hover {
  cursor: auto;
  background-color: rgba(247, 249, 252, 1);
}

.VTable .collapsible-row-opened {
  background-color: rgba(247, 249, 252, 1);
}

.VTable .collapsible-row-opened > td {
  border-bottom: none;
}
