@import url('https://fonts.googleapis.com/css?family=Roboto');

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.heading {
  font-family: 'Roboto';
  font-style: normal;
  display: flex;
  align-items: center;
}

.css-1-plus {
  font-weight: bold;
  font-size: 64px;
  line-height: 72px;
}

.css-1-plus-L {
  font-weight: 500;
  font-size: 64px;
  line-height: 72px;
  letter-spacing: 0.6px;
}

.css-1 {
  font-weight: bold;
  font-size: 40px;
  line-height: 48px;
}

.css-1-L {
  font-weight: normal;
  font-size: 40px;
  line-height: 48px;
}

.css-2 {
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
}

.css-3 {
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.8px;
}

.css-3-L {
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.6px;
}

.css-4 {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.3px;
}
