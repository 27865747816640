@import url('https://fonts.googleapis.com/css?family=Roboto');
@import '../CollapsibleHeadingsTable/CollapsibleHeadingsTable.css';

.VTable .last-row-table {
  background-color: #fff;
}

.VTable .MuiTableRow-root:not(.MuiTableRow-head).last-row-table:hover {
  cursor: auto;
  background-color: #fff;
}

.VTable .MuiTableRow-root.last-row-collapsed-table {
  background-color: #fff;
}

.VTable .MuiTableRow-root.last-row-collapsed-table:hover {
  cursor: auto;
  background-color: transparent;
}

.VTable .closed-table-effect div:first-child,
.VTable .closed-table-effect div:last-child {
  position: relative;
  z-index: 1;
  height: 12px;
  width: calc(100% - 16px);
  background-color: #ffffff;
  margin: auto;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  opacity: 0.7;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.12);
}

.VTable .closed-table-effect div:last-child {
  z-index: 0;
  opacity: 0.3;
  width: calc(100% - 32px);
}

.VTable .last-row-table-container {
  position: relative;
  z-index: 2;
}
