@import url('https://fonts.googleapis.com/css?family=Roboto');
.VaalSwitch.MuiSwitch-root {
  width: 56px;
  height: 40px;
}

.VaalSwitch .MuiSwitch-switchBase {
  color: #fff;
  padding: 13px;
}
.VaalSwitch .MuiSwitch-switchBase.Mui-checked {
  transform: translateX(16px);
}
.VaalSwitch .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  opacity: 1;
}
.VaalSwitch .MuiSwitch-colorPrimary.Mui-checked {
  color: rgba(47, 97, 224, 1);
}
.VaalSwitch .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
  background-color: rgba(47, 97, 224, 0.1);
}
.VaalSwitch .MuiSwitch-colorPrimary.Mui-checked:hover {
  background-color: transparent;
}
.VaalSwitch .MuiSwitch-colorPrimary.Mui-checked:hover ~ .MuiSwitch-track {
  background-color: rgba(47, 97, 224, 0.3);
}
.VaalSwitch .MuiSwitch-colorPrimary:hover ~ .MuiSwitch-track {
  opacity: 1;
}
.VaalSwitch .MuiSwitch-thumb {
  width: 14px;
  height: 14px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.12);
}
.VaalSwitch .MuiSwitch-track {
  border-radius: 100px;
  background-color: rgba(9, 30, 66, 1);
  opacity: 0.8;
}

.VaalSwitch .MuiIconButton-root:hover {
  background-color: transparent;
}
@media (hover: none) {
  .VaalSwitch .MuiIconButton-root:hover {
    background-color: transparent;
  }
}
.VaalSwitch .MuiIconButton-colorPrimary {
  color: rgba(47, 97, 224, 1);
}
