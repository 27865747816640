@import url('https://fonts.googleapis.com/css?family=Roboto');

.timeline-main-container {
  max-width: 435px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05), 0px 25px 30px rgba(0, 0, 0, 0.03);
  border-radius: 16px;
}

.timeline-header {
  padding: 26px;
  border-bottom: 1px solid #eaecef;
}

.timeline-footer {
  padding: 16px 24px;
  border-top: 1px solid #eaecef;
}

.timeline-list {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  padding: 0;
  margin: 26px 24px;
  max-height: 800px;
  overflow-y: auto;
}

.timeline-item-container {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
}

.timeline-item-container .timeline-item {
  display: flex;
  flex-direction: column;
  padding-left: 44px;
  margin-left: 17px;
}

.timeline-item-container:not(:last-child) .timeline-item {
  padding-bottom: 32px;
  border-left: 1px dashed #eaecef;
}

.timeline-item > div:first-child {
  margin-bottom: 14px;
}

.timeline-item-color {
  position: absolute;
  height: 34px;
  width: 34px;
  left: 0;
  border-radius: 50%;
  background-color: #fff;
  z-index: 2;
  overflow: hidden;
}

.timeline-item-color > div {
  height: 100%;
  width: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}
