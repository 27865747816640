@import '../Input/Input.css';
@import url('https://fonts.googleapis.com/css?family=Roboto');

.VaalSelect .MuiMenuItem-root {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  font-family: 'Roboto';
  font-style: normal;
  display: flex;
  align-items: center;
}

.VaalSelect .MuiInputBase-root {
  height: 48px;
}

.VaalSelect .MuiSelect-select:focus {
  background-color: transparent;
}

.VaalSelect .MuiSelect-icon {
  top: auto;
}

.VaalSelect .select-end-adornment svg:hover {
  opacity: 0.4;
}

.VaalSelect .MuiListItem-root.Mui-selected {
  background-color: transparent;
  color: #2f61e0;
}

.VaalSelect .MuiListItem-root.Mui-selected:after {
  content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='13' height='10' viewBox='0 0 13 10' fill='none' ><path d='M1.6665 5.33329L4.99984 8.66663L11.9998 1.66663' stroke='%232F61E0' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>");
  position: absolute;
  right: 16px;
}

.VaalSelect .MuiListItem-root.Mui-selected:hover {
  background-color: rgba(47, 97, 224, 0.05);
}

.VaalSelect .MuiListItem-button:hover {
  background-color: rgba(47, 97, 224, 0.05);
}

.VaalSelect .select-default-none.MuiListItem-root.Mui-selected:after {
  content: '';
}

.VaalSelect .MuiMenu-paper {
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05), 0px 25px 30px rgba(0, 0, 0, 0.03);
  border-radius: 4px;
}

.VaalSelect .MuiListSubheader-root {
  color: #091e42;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.6px;
}
