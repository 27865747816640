@import url('https://fonts.googleapis.com/css?family=Roboto');

.basic-label {
  min-width: 48px;
  height: 24px;
  border: none;
  outline: none;
  border-radius: 5px;
  padding: 0 9px;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.6px;
  white-space: nowrap;
}

.basic-label svg {
  height: 16px;
  width: 16px;
}

.numeric-label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;

  min-width: 14px;
  height: 14px;
  border: none;
  outline: none;
  border-radius: 3px;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
}

.numeric-label span {
  margin: 0 4px;
}
