@import url('https://fonts.googleapis.com/css?family=Roboto');

.to-do-list {
  list-style-type: none;
  padding: 0;
  max-width: 635px;
}

.to-do-list > li {
  margin-bottom: 14px;
}

.to-do-list > li:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 850px) {
  .to-do-list {
    margin-left: 5px;
    margin-right: 5px;
  }
}

/* TO DO ITEM*/

.to-do-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 632px;
  width: 100%;
  min-height: 82px;
  background-color: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05), 0px 25px 30px rgba(0, 0, 0, 0.03);
  border-top-right-radius: 14px;
  border-bottom-right-radius: 14px;
  border-left-style: solid;
  border-left-width: 3px;
  border-left-color: #2f61e0;
}

.to-do-item > div {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: 23px;
  margin-right: 26px;
  margin-top: 16px;
  margin-bottom: 16px;
  width: calc(100% - 23px - 26px);
  justify-content: space-between;
}

@media screen and (max-width: 700px) {
  .to-do-item > div {
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100% - 40px);
    flex-direction: column;
  }
}

.to-do-item > div span {
  display: inline;
  overflow: hidden;
  text-overflow: ellipsis;
}

.to-do-item-left-container {
  min-width: max-content;
  min-width: -moz-max-content;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 700px) {
  .to-do-item-left-container {
    min-width: auto;
    max-width: none;
  }
}

.to-do-item-right-container {
  min-width: max-content;
  min-width: -moz-max-content;
  max-width: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 700px) {
  .to-do-item-right-container {
    align-items: initial;
    min-width: auto;
    max-width: none;
  }
}
