.table-add-new-button {
  float: right;
}

.VaalTextField.small-date .MuiInputBase-root {
  min-width: 140px;
}

.VaalTextField.small-date .MuiOutlinedInput-adornedEnd {
  padding: 0px;
}

.VaalTextField.small-date .MuiOutlinedInput-inputAdornedEnd {
  padding-right: 0px !important;
}

.VaalTextField.small-date .MuiInputAdornment-positionEnd {
  margin-left: 0px;
}

.MuiTypography-root.MuiListItemText-primary {
  font-size: 12px;
  line-height: 16px;
  opacity: 0.5;
  letter-spacing: 0.4px;
}

.MuiTypography-root.MuiListItemText-secondary {
  font-size: 14px;
  line-height: 24px;
  opacity: 1;
  letter-spacing: 0.4px;
  color: inherit;
}

h4.MuiTypography-root.MuiListItemText-secondary {
  font-size: 18px;
  font-weight: 500;
  line-height: 38px;
}

.MuiListItemText-dense .MuiTypography-root.MuiListItemText-secondary {
  font-size: 12px;
  line-height: 18px;
}


/* DayPicker styles */

.DayPicker {
  display: inline-block;
  font-size: 14px;
}

.DayPicker-wrapper {
  position: relative;

  flex-direction: row;
  padding-bottom: 1em;

  -webkit-user-select: none;

     -moz-user-select: none;

      -ms-user-select: none;

          user-select: none;
}

.DayPicker-Months {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.DayPicker-Month {
  display: table;
  margin: 0 1em;
  margin-top: 1em;
  border-spacing: 0;
  border-collapse: collapse;

  -webkit-user-select: none;

     -moz-user-select: none;

      -ms-user-select: none;

          user-select: none;
}

.DayPicker-NavBar {
}

.DayPicker-NavButton {
  position: absolute;
  top: 1em;
  right: 1.2em;
  left: auto;

  display: inline-block;
  margin-top: -4px;
  width: 32px;
  height: 32px;
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
  color: #8B9898;
  cursor: pointer;
  background-color: rgba(47, 97, 224, 0.1);
  border-radius: 8px;
}

.DayPicker-NavButton:hover {
  opacity: 0.8;
}

.DayPicker-NavButton--prev {
  margin-right: 38.2em;
  background-image: url('./assets/images/Arrow-Left.svg');
}

.DayPicker-NavButton--next {
  background-image: url('./assets/images/Arrow-Right.svg');
}

.DayPicker-NavButton--interactionDisabled {
  display: none;
}

.DayPicker-Caption {
  display: table-caption;
  margin-bottom: 0.5em;
  padding: 0 0.5em;
  text-align: center;
}

.DayPicker-Caption > div {
  /* Paragraphs / P14 Bold */
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.6px;
  /* Põhivärvid / Vaga Vesi */
  color: #091E42;
}

.DayPicker-Weekdays {
  display: table-header-group;
  margin-top: 1em;
}

.DayPicker-WeekdaysRow {
  display: table-row;
}

.DayPicker-Weekday {
  display: table-cell;
  padding: 0.5em;
  color: #8B9898;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  color: #091E42;
  line-height: 24px;
}

.DayPicker-Weekday abbr[title] {
  border-bottom: none;
  text-decoration: none;
}

.DayPicker-Body {
  display: table-row-group;
}

.DayPicker-Week {
  display: table-row;
}

.DayPicker-Day {
  display: table-cell;
  padding: 6px 11px;
  border-radius: 50%;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
}

.DayPicker-WeekNumber {
  display: table-cell;
  padding: 0.5em;
  min-width: 1em;
  border-right: 1px solid #EAECEC;
  color: #8B9898;
  vertical-align: middle;
  text-align: right;
  font-size: 0.75em;
  cursor: pointer;
}

.DayPicker--interactionDisabled .DayPicker-Day {
  cursor: default;
}

.DayPicker-Footer {
  padding-top: 0.5em;
}

.DayPicker-TodayButton {
  border: none;
  background-color: transparent;
  background-image: none;
  box-shadow: none;
  color: #4A90E2;
  font-size: 0.875em;
  cursor: pointer;
}

/* Default modifiers */

.DayPicker-Day--today {
  /*color: #D0021B;
  font-weight: 700;*/
}

.DayPicker-Day--outside {
  color: #8B9898;
  cursor: default;
}

.DayPicker-Day--disabled {
  color: #A8ADB4;
  cursor: default;
  /* background-color: #eff1f1; */
}

/* Example modifiers */

.DayPicker-Day--sunday {
  background-color: #F7F8F8;
}

.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
  color: #DCE0E0;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  position: relative;

  background-color: #2F61E0;
  color: #FFFFFF;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #51A0FA;
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: #F0F8FF;
}

/* DayPickerInput */

.DayPickerInput {
  display: inline-block;
}

.DayPickerInput-OverlayWrapper {
  position: relative;
}

.DayPickerInput-Overlay {
  position: absolute;
  left: 0;
  z-index: 1;

  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.Range .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: rgba(47,97,224,0.1) !important;
  color: #091E42;
}
.Range .DayPicker-Day {
  border-radius: 0 !important;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance:none;
}

.VaalToggleButton .MuiToggleButton-root {
  color: #091e42;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.6px;
  text-transform: none;
  padding: 7px 16px;
}

.VaalToggleButton .MuiToggleButton-root,
.VaalToggleButton .MuiToggleButtonGroup-groupedHorizontal:not(:last-child),
.VaalToggleButton .MuiToggleButtonGroup-groupedHorizontal:not(:first-child) {
  border: 1px solid #eaecef;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 0;
}

.VaalToggleButton .MuiToggleButtonGroup-groupedHorizontal:not(:last-child) {
  margin-right: 12px;
}

.VaalToggleButton .MuiToggleButton-root:hover {
  background-color: rgba(47, 97, 223, 0.3);
  border: 1px solid rgba(47, 97, 223, 0.4);
}

.VaalToggleButton .MuiToggleButton-root.Mui-selected,
.VaalToggleButton .MuiToggleButton-root.Mui-selected + .MuiToggleButton-root.Mui-selected  {
  color: #091e42;
  background-color: rgba(47, 97, 223, 0.1);
  border: 1px solid rgba(47, 97, 223, 0.4);
}

.VaalToggleButton .MuiToggleButton-root.Mui-selected:hover {
  background-color: rgba(47, 97, 223, 0.3);
}

mark {
  background-color: rgba(47, 97, 224, 0.2);
}