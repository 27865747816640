.tab-container {
  background-color: rgba(47, 97, 224, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  border-radius: 8px;
  cursor: pointer;
}

.tab-small {
  height: 48px;
  border-radius: 4px;
}

.tab span {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tab-container .tab {
  flex: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 8px);
  margin-left: 2px;
  margin-right: 2px;
  border-radius: inherit;
  background: transparent;
  text-decoration: none;
}

.tab-container .tab.active {
  background: #ffffff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.12);
}

.tab-container .tab:first-child {
  margin-left: 4px;
}

.tab-container .tab:last-child {
  margin-right: 4px;
}

.tab-container .tab:not(.active):hover {
  background: rgba(255, 255, 255, 0.5);
}

.tab-popper-item a {
  font-weight: bold;
  font-size: 16px;
  color: #091E42;
  padding: 8px;
}

.tab-popper-item.selected {
  background-color: rgba(47, 97, 224, 0.1);
}