.notification {
  max-width: 538px;
  min-width: 338px;
  min-height: 80px;
  border-radius: 4px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.notification div:nth-child(odd) {
  margin: 28px 16px;
}

.notification div:nth-child(2) {
  margin: 28px 0;
  width: 100%;
  text-align: left;
}

.icon-special-color svg {
  opacity: 0.3;
  max-height: none;
  max-width: none;
  height: auto;
  width: auto;
}

.icon-special-color svg:hover {
  opacity: 1 !important;
  transition: all 0.2s;
}