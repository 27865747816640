@import url('https://fonts.googleapis.com/css?family=Roboto');

.paragraph {
  font-family: 'Roboto';
  font-style: normal;
  display: flex;
  align-items: center;
}

.css-16 {
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: 0.2px;
}

.css-16B {
  font-weight: bold;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: 0.6px;
}

.css-14 {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
}

.css-14B {
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.6px;
}

.css-12 {
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.6px;
}

.css-12B {
  font-weight: bold;
  font-size: 11px;
  line-height: 24px;
}
