@import url('https://fonts.googleapis.com/css?family=Roboto');

/* Medium / Default */
.btn,
.btn-fill:after {
  transition: all 0.2s;
}

.btn {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border-radius: 8px;
  line-height: 32px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  font-family: 'Roboto';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.6px;
  text-align: center;
  min-width: 120px;
  height: 56px;
  position: relative;
  opacity: 0.999;
  white-space: nowrap;
}

.btn-fill {
  background-color: rgba(47, 97, 224, 1);
  border: none;
  color: #ffffff;
  overflow: hidden;
}

.btn-fill:after {
  content: '';
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

/* Medium / Default / Hover & Pressed */
.btn-fill:hover:after,
.btn-fill:active:after,
.btn-fill:focus:after {
  opacity: 0.15;
}

/* Medium / Default / Hover */
.btn-fill:hover:after {
  background-color: #fff;
}

/* Medium / Default / Pressed */
.btn-fill:active:after,
.btn-fill:focus:after {
  background-color: rgba(9, 30, 66, 1);
}

/* Medium / Default / Disabled */
.btn[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

.btn[disabled].btn-fill:after {
  background-color: transparent;
}

/* Medium / Default / Loading */
.loading.btn-fill:after,
.loading.btn-fill:hover:after,
.loading.btn-fill:active:after,
.loading.btn-fill:focus:after {
  background-color: transparent;
}
.btn.loading {
  cursor: not-allowed;
}

.btn.loading svg {
  margin: auto;
  margin-right: 10px;
}

/* Medium / Secondary / Default */
.secondary.btn-fill {
  background-color: #fff;
  color: rgba(47, 97, 224, 1);
}

.secondary.btn-fill:after {
  opacity: 0.1;
  background-color: rgba(47, 97, 224, 1);
}

/* Medium / Secondary / Hover & Pressed */
.secondary.btn-fill:hover:after,
.secondary.btn-fill:active:after,
.secondary.btn-fill:focus:after {
  background-color: rgba(47, 97, 224, 1);
}

/* Medium / Secondary / Hover */
.secondary.btn-fill:hover:after {
  opacity: 0.2;
}

/* Medium / Secondary / Pressed */
.secondary.btn-fill:active:after,
.secondary.btn-fill:focus:after {
  opacity: 0.25;
}

/* Medium / Tertiary / Default */
.tertiary {
  filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.12));
}

.tertiary.btn-fill {
  background-color: #fff;
  color: rgba(47, 97, 224, 1);
}

/* Medium / Tertiary / Hover & Pressed */
.tertiary.btn-fill:hover:after,
.tertiary.btn-fill:active:after,
.tertiary.btn-fill:focus:after {
  background-color: transparent;
}

/* Medium / Tertiary / Hover */
.tertiary:hover {
  color: rgba(43, 90, 208, 1);
}

/* Medium / Tertiary / Pressed */
.tertiary:active,
.tertiary:focus {
  color: rgba(36, 77, 177, 1);
}

/* SMALL AND EXTRA SMALL BUTTONS */
.small.btn {
  min-width: 96px;
  height: 40px;
  padding: 8px;
  font-size: 14px;
  line-height: 24px;
}

.extra-small.btn {
  min-width: 72px;
  height: 32px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 11px;
  line-height: 24px;
}
