.custom-file-input {
  display: inline-block;
  position: relative;
  height: 24px;
  width: 24px;
  border: none;
  background: rgba(47, 97, 224, 0.1);
  border-radius: 4px;
  cursor: pointer;
}

.custom-file-input input {
  visibility: hidden;
}

.custom-file-input:after {
  display: block;
  margin: 0 0 0 16px;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
  text-align: left;
  position: absolute;
  left: 100%;
  top: 0;
}

.custom-file-input svg {
  position: absolute;
  transform: rotate(45deg);
  height: 18px;
  width: 18px;
}
